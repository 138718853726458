import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./home.css";
import NavBar from "../../Components/navbar/navbar";
import {
  Input,
  Button,
  Row,
  Col,
  Select,
  message,
  Progress,
} from "antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  getAnnulerParcels,
  getCoursParcels,
  getLivreParcels,
  getRetourParcels,
  sendDriverPosition,
  updateParcelsOrder,
} from "../../store/actions/parcelActions";
import { useNavigate } from "react-router-dom";
import ParcelCard from "./parcelCard/parcelCard";
import { QrReader } from "react-qr-reader";
import ParcelServices from "../../store/services/parcelServices";
const { Option } = Select;

let searchTimeout;

const Home = () => {
  const navigate = useNavigate();
  // BOTTOM BUTTONS FILTER LOGIC
  const [activeStoreName, setActiveStoreName] = useState("coursParcels");

  const [encoursFilter, setEncoursFilter] = useState(true);
  const [annuleFilter, setAnnuleFilter] = useState(false);
  const [reporteFilter, setReporteFilter] = useState(false);
  const [livreFilter, setLivreFilter] = useState(false);
  const [zones, setZones] = useState([]);

  useEffect(() => {
    const fetchZones = async () => {
      await ParcelServices.fetchDriverZones()
        .then(response => {
          const data = Array.isArray(response.data) ? response.data : [];
          setZones(data);
        })
        .catch(error => {
          setZones([]);
          console.error('Error fetching zones:', error);
        });
    };

    fetchZones();
  }, []);

  const setEnCours = () => {
    setNid('');
    dispatch(getCoursParcels());
    setActiveStoreName("coursParcels");
    setEncoursFilter(true);
    setAnnuleFilter(false);
    setLivreFilter(false);
    setReporteFilter(false);
  };

  const setAnnuler = () => {
    setNid('');
    dispatch(getAnnulerParcels());
    setActiveStoreName("annulerParcels");
    setEncoursFilter(false);
    setAnnuleFilter(true);
    setLivreFilter(false);
    setReporteFilter(false);
  };
  const setLivre = () => {
    setNid('');
    dispatch(getLivreParcels());
    setActiveStoreName("livreParcels");
    setEncoursFilter(false);
    setAnnuleFilter(false);
    setLivreFilter(true);
    setReporteFilter(false);
  };

  const setReporte = () => {
    setNid('');
    dispatch(getRetourParcels());
    setActiveStoreName("retourParcels");
    setEncoursFilter(false);
    setAnnuleFilter(false);
    setLivreFilter(false);
    setReporteFilter(true);
  };

  const [searchField, setSearchField] = useState("");
  const [nid, setNid] = useState("");
  const [cameraOpen, setCameraOpen] = useState(false);
  const data = useSelector((state) => state);

  const dispatch = useDispatch();
  const [searchZone, setSearchZone] = useState("");

  const handleChangeSearch = (e) => {
    // setSearchField(e.target.value);
    setNid(e.target.value);

    const query = {};

    e.target.value && (query.nid = e.target.value);

    clearTimeout(searchTimeout);

    searchTimeout = setTimeout(() => {
      switch (activeStoreName) {
        case "coursParcels":
          dispatch(getCoursParcels(query));
          break;
        case "annulerParcels":
          dispatch(getAnnulerParcels(query));
          break;
        case "livreParcels":
          dispatch(getLivreParcels(query));
          break;
        case "retourParcels":
          dispatch(getRetourParcels(query));
          break;

        default:
          break;
      }
    }, 400);

  };

  function onChangeSearchZone(value) {
    if (value === 'all') {
      setSearchZone("");
    } else {
      setSearchZone(value);
    }
  }

  function calculertotal(parcels) {
    let total = 0;
    for (let i = 0; i < parcels?.length; i++) {
      total += parcels[i]?.jobRequest?.amount;
    }
    return total?.toFixed(2);
  }
  function handleOnDragEnd(result) {
    if (!result.destination) return;
    dispatch(updateParcelsOrder(result));
  }

  useEffect(() => {
    dispatch(getCoursParcels());
  }, []);

  function sendMy() {
    if (!navigator.geolocation) {
      message.error("La localisation n'est pas supportée par votre navigateur.");
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          dispatch(
            sendDriverPosition(
              position.coords.latitude,
              position.coords.longitude
            )
          );
        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              message.error("Tu as refusé la demande de localisation.");
              break;
            case error.POSITION_UNAVAILABLE:
              message.error("Les informations de localisation ne sont pas disponibles.");
              break;
            case error.TIMEOUT:
              message.error("La demande de localisation a expiré.");
              break;
            case error.UNKNOWN_ERROR:
              message.error("Une erreur inconnue s'est produite.");
              break;
          }
        }
      );
    }
  }

  useEffect(() => {
    sendMy();
    const interval = setInterval(() => {
      sendMy();
    }, 900000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
  }, []);

  function clearCache() {
    localStorage.clear();
    sessionStorage.clear();

    document.cookie.split(";").forEach(function (c) {
      document.cookie = c.trim().split("=")[0] + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
    });

    window.location.href = '/';
  }

  const openCamera = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(() => {
          setCameraOpen(!cameraOpen);
        })
        .catch((err) => {
          message.error("Tu as refusé la demande de camera.");
        });
    } else {
      message.error("La caméra n'est pas disponible sur ce navigateur.");
    }
  };

  const handleScan = (data) => {
    if (data) {
      const isValidMongoId = /^[0-9a-fA-F]{24}$/.test(data.text);
      if (isValidMongoId) {
        setCameraOpen(false);
        navigate(`/parcel/${data.text}`);
      } else {
        message.error("Les données scannées ne sont pas valides.");
      }
    }
  };

  const handleError = (err) => {
    console.error(err);
    setCameraOpen(false);
  };

  return (
    <>
      <NavBar openCamera={openCamera} />
      <div className="home-container">
        {cameraOpen && (
          <div className="camera-container">
            <QrReader
              delay={300}
              onError={handleError}
              onResult={handleScan}
              style={{ width: '100%' }}
              constraints={{ facingMode: 'environment' }}
            />
          </div>
        )}

        <Row className="search-parcel">
          <Col span={24}>
            <Input
              className="input-parcel"
              type="text"
              value={nid}
              onChange={handleChangeSearch}
              placeholder="Rechercher ..."
            />
          </Col>
        </Row>
        <Row className="search-parcel">
          <Select
            className="select-parcel"
            placeholder="Tous les zones"
            optionFilterProp="children"
            onChange={onChangeSearchZone}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            style={{ width: "100%" }}
          >
            <Option className="select-parcel-option" value="all">Tous les zones</Option>
            {zones.map((zone) => (
              <Option className="select-parcel-option" key={zone} value={zone}>{zone}</Option>
            ))}
          </Select>
        </Row>

        {(data.parcels.cacheResponse && !data.parcels?.loading) && <div className="cache-text"> Données du cache </div>}

        {data.parcels?.loading ? (
          <div className="loading-text"> Chargement ... </div>
        ) : (
          <div className="list-container">
            {livreFilter ? (
              <div>
                {" "}
                <Progress
                  strokeColor={{
                    "100%": "#557eda",
                    "0%": "#ea7d1a",
                  }}
                  percent={Math.round(
                    (data.parcels?.livreParcels?.length / data.parcels?.total) *
                    100
                  ).toFixed(0)}
                />
                <div
                  style={{
                    textAlign: "center",
                    fontSize: " 1.25em",
                    letterSpacing: "1px",
                  }}
                >
                  {" "}
                  Montant collecté :{" "}
                  <span style={{ fontWeight: "600" }}>
                    {calculertotal(data.parcels?.livreParcels)}
                  </span>{" "}
                  Dinars{" "}
                </div>
              </div>
            ) : null}
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable
                isDropDisabled={
                  !encoursFilter || searchField != "" || searchZone != ""
                }
                droppableId="parcels"
              >
                {(provided) => (
                  <ul
                    className="parcels"
                    style={{ listStyle: "none" }}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {data.parcels?.[activeStoreName]
                      ?.filter(function (p) {
                        return (
                          (searchField
                            ? p.jobRequest.nid?.indexOf(searchField) !== -1 ||
                            p.jobRequest.descProduit?.indexOf(searchField) !==
                            -1 ||
                            p.jobRequest.addInfo?.indexOf(searchField) !==
                            -1 ||
                            p.jobRequest.business?.nomCommercial?.indexOf(
                              searchField
                            ) !== -1 ||
                            p.jobRequest.deliveredToName?.indexOf(
                              searchField
                            ) !== -1 ||
                            p.jobRequest.deliveredToPhone?.indexOf(
                              searchField
                            ) !== -1
                            : true) &&
                          (searchZone ? p.zone === searchZone : true)
                        );
                      })
                      .map((parcel, index) => {
                        return (
                          <Draggable
                            key={parcel?.jobRequest?.nid + index}
                            draggableId={parcel?.jobRequest?.nid}
                            index={index}
                          >
                            {(provided) => (
                              <li
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <ParcelCard parcel={parcel} zones={zones} disableActions={data.parcels.cacheResponse}></ParcelCard>
                              </li>
                            )}
                          </Draggable>
                        );
                      })}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
            <Row>
              <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                <button
                  onClick={clearCache}
                  style={{
                    padding: '10px 20px',
                    backgroundColor: '#007bff',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px'
                  }}
                >
                  Vider le cache
                </button>
              </Col>
            </Row>
          </div>
        )}
      </div>
      <Row className="bottom-filter">
        <Col span={6}>
          <Button
            type="default"
            className={
              encoursFilter
                ? "bottom-filter-btn bottom-btn-selected"
                : "bottom-filter-btn"
            }
            onClick={() => setEnCours()}
          >
            {/* <ClockCircleOutlined /> */}
            <div>En cours</div>
          </Button>
        </Col>

        <Col span={6}>
          <Button
            type="default"
            className={
              livreFilter
                ? "bottom-filter-btn bottom-btn-selected"
                : "bottom-filter-btn"
            }
            onClick={() => setLivre()}
          >
            {/* <LockOutlined /> */}
            <div>Livrés</div>
          </Button>
        </Col>

        <Col span={6}>
          <Button
            type="default"
            className={
              reporteFilter
                ? "bottom-filter-btn bottom-btn-selected"
                : "bottom-filter-btn"
            }
            onClick={() => setReporte()}
          >
            {/* <RedoOutlined /> */}
            <div>Reportés</div>
          </Button>
        </Col>

        <Col span={6}>
          <Button
            type="default"
            className={
              annuleFilter
                ? "bottom-filter-btn bottom-btn-selected"
                : "bottom-filter-btn"
            }
            onClick={() => setAnnuler()}
          >
            {/* <ExclamationCircleOutlined /> */}
            <div>Annulés</div>
          </Button>
        </Col>

        {/* <Col span={6}>
          <Button
            type="default"
            className={
              rankingFilter
                ? "bottom-filter-btn bottom-btn-selected"
                : "bottom-filter-btn"
            }
            onClick={() => setRanking()}
          >
            <ExclamationCircleOutlined />
            <div>Classement</div>
          </Button>
        </Col> */}
      </Row>
    </>
  );
};

export default Home;
