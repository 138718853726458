import {
  TOGGLE_SCAN_CAMERA,
  UPDATE_PARCEL_REQUEST,
  UPDATE_PARCEL_SUCCESS,
  UPDATE_PARCEL_FAILURE,
  GET_ONE_PARCEL_REQUEST,
  GET_ONE_PARCEL_SUCCESS,
  GET_ONE_PARCEL_FAILURE,
  CALL_CLIENT_REQUEST,
  GET_DRIVER_RANKING,
  CALL_CLIENT_SUCCESS,
  TOGGLE_LOADING,
  SMS_CLIENT_REQUEST,
  SMS_CLIENT_SUCCESS,
  SMS_CLIENT_FAILURE,
  GET_SMS_REQUEST,
  GET_SMS_SUCCESS,
  GET_SMS_FAILURE,
  SEND_POSITION_REQUEST,
  SEND_POSITION_SUCCESS,
  SET_SCANNED_PARCEL,
  SET_PARCEL_IMAGE,
  SEND_POSITION_FAILURE,
  GET_TOTAL_PARCELS_SUCCESS,
  GET_EN_COURS_PARCELS_REQUEST,
  GET_LIVRER_PARCELS_REQUEST,
  GET_LIVRER_PARCELS_SUCCESS,
  GET_LIVRER_PARCELS_FAILURE,
  GET_EN_COURS_PARCELS_SUCCESS,
  GET_EN_COURS_PARCELS_FAILURE,
  GET_RETOUR_PARCELS_REQUEST,
  GET_RETOUR_PARCELS_SUCCESS,
  GET_RETOUR_PARCELS_FAILURE,
  PARCEL_SIZE_UPDATED_SUCCESS,
  GET_ANNULER_PARCELS_REQUEST,
  GET_ANNULER_PARCELS_SUCCESS,
  GET_ANNULER_PARCELS_FAILURE,
  UPDATE_PARCELS_ORDER,
  GET_MESSAGES_REQUEST,
} from "../types";
import { message } from "antd";
import moment from "moment";
import ParcelServices from "../services/parcelServices";

//GETTING Parcelsw

export function getCoursParcels(query) {
  return async (dispatch) => {
    await dispatch({ type: GET_EN_COURS_PARCELS_REQUEST });
    try {

      const response = await ParcelServices.getParcels({ status: 10, ...query });

      await dispatch({ type: GET_EN_COURS_PARCELS_SUCCESS, payload: response.data });

      const data = { data: response.data, date: new Date() };
      localStorage.setItem('pwa_driver_in_progress_parcels', JSON.stringify(data));

    } catch (e) {
      const cachedData = fetchCachedData('pwa_driver_in_progress_parcels');

      if (cachedData) return await dispatch({ type: GET_EN_COURS_PARCELS_SUCCESS, payload: cachedData, cacheResponse: true, });

      dispatch({ type: GET_EN_COURS_PARCELS_FAILURE });
      message.error("Merci de verifier si vous avez des colis en cours");
    }
  };
}

// EN COURS 10 LIVREE 6 RETOUR 15 ANNULER 14
export function getLivreParcels(query) {
  return async (dispatch) => {
    await dispatch({ type: GET_LIVRER_PARCELS_REQUEST });
    try {
      const response = await ParcelServices.getParcels({ status: 6, ...query });
      await dispatch({ type: GET_LIVRER_PARCELS_SUCCESS, payload: response.data });

      const response1 = await ParcelServices.getParcels({ status: null });
      await dispatch({
        type: GET_TOTAL_PARCELS_SUCCESS,
        payload: response1.data.length,
      });

      const data = { data: response.data, date: new Date() };
      localStorage.setItem('pwa_driver_delivered_parcels', JSON.stringify(data));

    } catch (e) {
      const cachedData = fetchCachedData('pwa_driver_delivered_parcels');

      if (cachedData) return await dispatch({ type: GET_LIVRER_PARCELS_SUCCESS, payload: cachedData, cacheResponse: true, });

      dispatch({ type: GET_LIVRER_PARCELS_FAILURE });
      message.error("Merci de verifier si vous avez des colis livrées");
    }
  };
}

export function getRetourParcels(query) {
  return async (dispatch) => {
    await dispatch({ type: GET_RETOUR_PARCELS_REQUEST });
    try {
      const response = await ParcelServices.getParcels({ status: 15, ...query });
      await dispatch({ type: GET_RETOUR_PARCELS_SUCCESS, payload: response.data });

      const data = { data: response.data, date: new Date() };
      localStorage.setItem('pwa_driver_returned_parcels', JSON.stringify(data));

    } catch (e) {
      const cachedData = fetchCachedData('pwa_driver_returned_parcels');

      if (cachedData) return await dispatch({ type: GET_RETOUR_PARCELS_SUCCESS, payload: cachedData, cacheResponse: true, });

      dispatch({ type: GET_RETOUR_PARCELS_FAILURE });
      message.error("Merci de verifier si vous avez des colis retour");
    }
  };
}

export function getAnnulerParcels(query) {
  return async (dispatch) => {
    await dispatch({ type: GET_ANNULER_PARCELS_REQUEST });
    try {
      const response = await ParcelServices.getParcels({ ...query, status: 15, failureReason: "Livraison annulée" });
      await dispatch({ type: GET_ANNULER_PARCELS_SUCCESS, payload: response.data });

      const data = { data: response.data, date: new Date() };
      localStorage.setItem('pwa_driver_canceled_parcels', JSON.stringify(data));

    } catch (e) {
      const cachedData = fetchCachedData('pwa_driver_canceled_parcels');

      if (cachedData) return await dispatch({ type: GET_ANNULER_PARCELS_SUCCESS, payload: cachedData, cacheResponse: true, });

      dispatch({ type: GET_ANNULER_PARCELS_FAILURE });
      message.error("Merci de verifier si vous avez des colis annulés");
    }
  };
}

function fetchCachedData(key) {
  const cached = localStorage.getItem(key);
  const jsonData = JSON.parse(cached);

  if (!cached) return;

  if (moment().isSame(jsonData.date, 'day')) return jsonData.data;

  localStorage.removeItem(key);
}

export function getSortedDrivers(query) {
  return async (dispatch) => {

    try {
      const response = await ParcelServices.getSortedDrivers(query);

      await dispatch({ type: GET_DRIVER_RANKING, payload: response.data });

    } catch (e) {
      // dispatch({ type: GET_RETOUR_PARCELS_FAILURE });
      message.error("Merci de réessayé");
    }
  };
}

export function updateParcelsOrder(result) {
  return async (dispatch) => {
    try {
      await dispatch({ type: UPDATE_PARCELS_ORDER, payload: result });
    } catch (e) {
      message.error("Erreur lors du changement d'ordre des colis!");
    }
  };
}
// GET ONE PARCEL
export function getOneParcelByID(id) {
  return async (dispatch) => {
    await dispatch({ type: GET_ONE_PARCEL_REQUEST });
    try {
      const response = await ParcelServices.getOneParcel(id);
      await dispatch({ type: GET_ONE_PARCEL_SUCCESS, payload: response.data });
    } catch (e) {
      dispatch({ type: GET_ONE_PARCEL_FAILURE });
      message.error("Merci de verifier si vous ètes le livreur de ce coli");
    }
  };
}
export function updateParcel(id, zone, time) {
  return async (dispatch) => {

    await dispatch({ type: UPDATE_PARCEL_REQUEST });

    try {
      const response = await ParcelServices.updateParcel(id, {
        zone: zone,
        deliveryTime: new Date(time),
      });

      await dispatch({ type: UPDATE_PARCEL_SUCCESS, payload: response });

      const response1 = await ParcelServices.getParcels({ status: 10 });

      await dispatch({ type: GET_EN_COURS_PARCELS_SUCCESS, payload: response1.data });

      // const response2 = await ParcelServices.getOneParcel(id);

      // await dispatch({ type: GET_ONE_PARCEL_SUCCESS, payload: response2.data });

    } catch (e) {
      dispatch({ type: UPDATE_PARCEL_FAILURE });
      message.error("OOPS! le coli n'étais pas mise à jour");
    }
  };
}

export function updateParcelStatus(id, status, reason, date, runsheetID, pinCode, deliveryGeolocation) {
  return async (dispatch) => {
    await dispatch({ type: UPDATE_PARCEL_REQUEST });
    try {
      //console.log("DATE IS HERE", date);
      const response = await ParcelServices.updateParcelStatus({
        jobRequestID: id,
        status: status,
        failureReason: reason ?? "",
        reportedDate: date ?? "",
        pinCode: pinCode ?? null,
        deliveryGeolocation: deliveryGeolocation ?? null,
      });

      const response1 = await ParcelServices.getParcels({
        status: status === 10 ? 15 : 10,
      });
      if (runsheetID) {
        const response2 = await ParcelServices.getOneParcel(runsheetID);
        await dispatch({
          type: GET_ONE_PARCEL_SUCCESS,
          payload: response2.data,
        });
      }
      await dispatch({ type: UPDATE_PARCEL_SUCCESS, payload: response.data });
      await dispatch({ type: status === 10 ? GET_RETOUR_PARCELS_SUCCESS : GET_EN_COURS_PARCELS_SUCCESS, payload: response1.data });
    } catch (e) {
      dispatch({ type: UPDATE_PARCEL_FAILURE });
      const errorMessage = e.response?.data?.message || "une erreur s'est produite, réessayez";
      message.error(errorMessage);
    }
  };
}

export function deliverPaidParcel(id, smsCode,) {
  return async (dispatch) => {

    await dispatch({ type: UPDATE_PARCEL_REQUEST });

    try {
      const response = await ParcelServices.deliverPaidParcel({ id, smsCode });

      if (!response.data?.success) {
        const raison = response.data?.raison || "Un erreur s'est produite";

        return message.error(raison);
      }

      const response1 = await ParcelServices.getParcels({
        status: 10,
      });

      await dispatch({ type: UPDATE_PARCEL_SUCCESS, payload: response.data });

      await dispatch({ type: GET_EN_COURS_PARCELS_SUCCESS, payload: response1.data });

    } catch (e) {
      message.error("Un erreur s'est produite");
    }
  };
}

export function sendSmsToClient(id) {
  return async (dispatch) => {

    try {
      const response = await ParcelServices.sendSmsToClient({ _id: id });

      if (response?.data?.success) {
        return message.success("SMS envoyé avec succès.");
      }

      message.error(response?.data?.raison || "Un erreur s'est produite");

    } catch (e) {
      message.error("Un erreur s'est produite");
    }
  };
}

export function callClient(jobRequest, phone, failureNumber, disableError) {
  return async (dispatch) => {
    await dispatch({ type: CALL_CLIENT_REQUEST });
    try {
      const response = await ParcelServices.callClient({
        jobRequestID: jobRequest,
      });

      await dispatch({ type: CALL_CLIENT_SUCCESS, payload: response.data });

      if (!disableError) window.open("tel:70223008");

    } catch (e) {
      if (!disableError) message.error("Echéc d'appel!");

      // dispatch({ type: CALL_CLIENT_FAILURE });
      // if (failureNumber) {
      //   window.open(failureNumber);
      // } else {
      //   message.error("Echéc d'appel!");
      // }
    }
  };
}

export function callClientDirectly(jobRequest, phone) {
  return async (dispatch) => {
    await dispatch({ type: CALL_CLIENT_REQUEST });
    try {
      const response = await ParcelServices.callClient({
        jobRequestID: jobRequest,
      });

      await dispatch({ type: CALL_CLIENT_SUCCESS, payload: response.data });

      window.open(`tel:${phone}`);
    } catch (e) {
      console.log(e);
      message.error("Echéc d'appel!");
    }
  };
};

export function callBusinessDirectly(phone) {
  return async (dispatch) => {
    await dispatch({ type: CALL_CLIENT_REQUEST });
    try {
      window.open(`tel:${phone}`);
    } catch (e) {
      console.log(e);
      message.error("Echéc d'appel!");
    }
  };
};

export function smsClient(text, phone) {
  return async (dispatch) => {
    await dispatch({ type: SMS_CLIENT_REQUEST });
    try {
      await ParcelServices.smsClient({
        Text: text,
        Phone: phone,
      });
      await dispatch({ type: SMS_CLIENT_SUCCESS, payload: { success: true } });
    } catch (e) {
      dispatch({ type: SMS_CLIENT_FAILURE });
      message.error("L'envoi de SMS a échoué :(");
    }
  };
}

export function getSmsList() {
  return async (dispatch) => {
    await dispatch({ type: GET_SMS_REQUEST });
    try {
      // const response = await ParcelServices.getSMS();
      // await dispatch({ type: GET_SMS_SUCCESS, payload: response.data });
    } catch (e) {
      dispatch({ type: GET_SMS_FAILURE });
      message.error("Merci de verifier si vous avez des pickups");
    }
  };
}

export function sendDriverPosition(lat, lng) {
  return async (dispatch) => {
    await dispatch({ type: SEND_POSITION_REQUEST });
    try {
      const response = await ParcelServices.sendPosition({
        latitude: lat,
        longitude: lng,
      });
      await dispatch({ type: SEND_POSITION_SUCCESS, payload: response.data });
    } catch (e) {
      dispatch({ type: SEND_POSITION_FAILURE });
      // message.error("Merci de verifier si vous avez activé la localisation");
    }
  };
}

const success_audio = new Audio(require("../../Assets/sounds/done.mp3"));
const fail_audio = new Audio(require("../../Assets/sounds/fail.mp3"));

export function scanLargeParcel(id, payload) {

  return async (dispatch) => {

    try {
      await dispatch({ type: TOGGLE_LOADING, payload: true });

      const response = await ParcelServices.scanLargeParcel(id, payload);

      success_audio.play();
      message.success("La taille est modifié avec succès.");

      await dispatch({ type: PARCEL_SIZE_UPDATED_SUCCESS, payload: response.data });

    } catch (e) {
      await dispatch({ type: TOGGLE_LOADING, payload: false });

      const errorMessage = e.response && e.response.data && e.response.data.message
        ? e.response.data.message
        : "Erreur lors du scan du colis";

      fail_audio.play();
      message.error(errorMessage);
    }
  };
}

export function getPackingSlipJob(id, secretCode) {

  return async (dispatch) => {

    try {
      await dispatch({ type: TOGGLE_LOADING, payload: true });

      const response = await ParcelServices.getPackingSlipJob(id, secretCode);

      success_audio.play();
      // message.success("Colis scanné avec succès.");

      await dispatch({ type: SET_SCANNED_PARCEL, payload: response.data });

    } catch (e) {
      await dispatch({ type: TOGGLE_LOADING, payload: false });
      fail_audio.play();
      message.error(e.message || "Erreur lors du scan du colis");
    }
  };
}

export function toggleScanCamera(value) {

  return async (dispatch) => {

    try {
      await dispatch({ type: TOGGLE_SCAN_CAMERA, payload: value });

    } catch (e) {
      fail_audio.play();
      message.error(e.message || "Erreur lors du scan du colis");
    }
  };
}

export function setParcelImage(payload) {

  return async (dispatch) => {

    try {
      await dispatch({ type: SET_PARCEL_IMAGE, payload });

    } catch (e) {
      console.error('e:', e)
      message.error("Un erreur s'est produite");
    }
  };
}

export function getDriverMessages() {
  return async (dispatch) => {

    try {
      const response = await ParcelServices.getDriverMessages();

      await dispatch({ type: GET_MESSAGES_REQUEST, payload: response.data });

    } catch (e) {
      message.error("Merci de réessayé");
    }
  };
}
