export const SIGNIN_REQUEST = "SIGNIN_REQUEST ";
export const SIGNIN_FAILURE = "SIGNIN_FAILURE ";
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS ";
export const LOGOUT = "LOGOUT";

export const GET_PARCELS_REQUEST = "GET_PARCELS_REQUEST ";
export const GET_PARCELS_SUCCESS = "GET_PARCELS_SUCCESS ";
export const GET_PARCELS_FAILURE = "GET_PARCELS_FAILURE ";
export const GET_EN_COURS_PARCELS_REQUEST = "GET_EN_COURS_PARCELS_REQUEST ";
export const GET_EN_COURS_PARCELS_SUCCESS = "GET_EN_COURS_PARCELS_SUCCESS ";
export const GET_EN_COURS_PARCELS_FAILURE = "GET_EN_COURS_PARCELS_FAILURE ";

export const GET_LIVRER_PARCELS_REQUEST = "GET_LIVRER_PARCELS_REQUEST ";
export const GET_LIVRER_PARCELS_SUCCESS = "GET_LIVRER_PARCELS_SUCCESS ";
export const GET_LIVRER_PARCELS_FAILURE = "GET_LIVRER_PARCELS_FAILURE ";

export const GET_RETOUR_PARCELS_REQUEST = "GET_RETOUR_PARCELS_REQUEST ";
export const GET_RETOUR_PARCELS_SUCCESS = "GET_RETOUR_PARCELS_SUCCESS ";
export const GET_DRIVER_RANKING = "GET_DRIVER_RANKING ";
export const GET_RETOUR_PARCELS_FAILURE = "GET_RETOUR_PARCELS_FAILURE ";
export const PARCEL_SIZE_UPDATED_SUCCESS = "GET_RETOUR_PARCELS_FAILURE ";
export const TOGGLE_LOADING = "TOGGLE_LOADING ";

export const GET_ANNULER_PARCELS_REQUEST = "GET_ANNULER_PARCELS_REQUEST ";
export const GET_ANNULER_PARCELS_SUCCESS = "GET_ANNULER_PARCELS_SUCCESS ";
export const GET_ANNULER_PARCELS_FAILURE = "GET_ANNULER_PARCELS_FAILURE ";

export const GET_TOTAL_PARCELS_SUCCESS = "GET_TOTAL_PARCELS_SUCCESS ";

export const GET_ONE_PARCEL_REQUEST = "GET_ONE_PARCEL_REQUEST ";
export const GET_ONE_PARCEL_SUCCESS = "GET_ONE_PARCEL_SUCCESS ";
export const GET_ONE_PARCEL_FAILURE = "GET_ONE_PARCEL_FAILURE ";
export const TOGGLE_SCAN_CAMERA = "TOGGLE_SCAN_CAMERA ";

export const UPDATE_PARCELS_ORDER = "UPDATE_PARCELS_ORDER ";

export const UPDATE_PARCEL_REQUEST = "UPDATE_PARCEL_REQUEST ";
export const UPDATE_PARCEL_SUCCESS = "UPDATE_PARCEL_SUCCESS ";
export const UPDATE_PARCEL_FAILURE = "UPDATE_PARCEL_FAILURE ";

export const CALL_CLIENT_REQUEST = "CALL_CLIENT_REQUEST ";
export const CALL_CLIENT_SUCCESS = "CALL_CLIENT_SUCCESS ";
export const CALL_CLIENT_FAILURE = "CALL_CLIENT_FAILURE ";

export const SMS_CLIENT_REQUEST = "SMS_CLIENT_REQUEST ";
export const SMS_CLIENT_SUCCESS = "SMS_CLIENT_SUCCESS ";
export const SMS_CLIENT_FAILURE = "SMS_CLIENT_FAILURE ";

export const GET_SMS_REQUEST = "GET_SMS_REQUEST ";
export const GET_SMS_SUCCESS = "GET_SMS_SUCCESS ";
export const GET_SMS_FAILURE = "GET_SMS_FAILURE ";

export const SEND_POSITION_REQUEST = "SEND_POSITION_REQUEST ";
export const SEND_POSITION_SUCCESS = "SEND_POSITION_SUCCESS ";
export const SEND_POSITION_FAILURE = "SEND_POSITION_FAILURE ";
export const SET_SCANNED_PARCEL = "SET_SCANNED_PARCEL ";
export const SET_PARCEL_IMAGE = "SET_PARCEL_IMAGE ";

export const GET_MESSAGES_REQUEST = "GET_MESSAGES_REQUEST ";
export const GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS ";
export const GET_MESSAGES_FAILURE = "GET_MESSAGES_FAILURE ";
