import config from "../../Config/config";

function getParcels(payload) {
  return config.axiosDriverInstance({
    method: "get",
    url: "runsheets/jobrequests",
    params: { ...payload, timestamp: Date.now() },
  });
}

function getSortedDrivers(params) {
  return config.axiosAdminInstance({
    method: "get",
    url: "drivers/sorted",
    params,
  });
}

function updateParcel(_id, payload) {
  !payload.zone && (delete payload.zone);

  return config.axiosDriverInstance({
    method: "put",
    url: `runsheets/${_id}/zone`,
    data: payload,
  });
}

function updateParcelStatus({ jobRequestID, status, failureReason, reportedDate, pinCode, deliveryGeolocation }) {
  return config.axiosDriverInstance({
    method: "put",
    url: `jobrequests/${jobRequestID}/status`,
    data: { status, failureReason, reportedDate, pinCode, deliveryGeolocation },
  });
}

function deliverPaidParcel({ id, smsCode, }) {
  return config.axiosDriverInstance({
    method: "post",
    url: `jobrequests/${id}/deliver-paied`,
    data: { smsCode },
  });
}

function sendSmsToClient({ _id }) {
  return config.axiosDriverInstance({
    method: "post",
    url: `jobrequests/sms/final-client`,
    data: { _id },
  });
}

function getOneParcel(id) {
  return config.axiosDriverInstance({
    method: "get",
    url: "runsheets/runsheet-item/" + id,
    //data: null,
  });
}

function callClient(values) {
  return config.axiosDriverInstance({
    method: "post",
    url: `jobrequests/${values.jobRequestID}/tentatives`,
    data: { phone: values.phone },
  });
}

function smsClient(values) {
  return config.axiosDriverInstance({
    method: "post",
    url: "driver/smsClient",
    data: values,
  });
}
function getSMS() {
  // return config.axiosDriverInstance({
  //   method: "post",
  //   url: "driver/smsList",
  //   //data: values,
  // });
}

function sendPosition(values) {
  return config.axiosDriverInstance({
    method: "post",
    url: "drivers/position",
    data: values,
  });
}

function scanLargeParcel(id, payload) {
  return config.axiosDriverInstance({
    method: "post",
    url: `jobrequests/${id}/size`,
    data: payload,
  });
}

function getPackingSlipJob(id, secretCode) {
  return config.axiosDriverInstance({
    method: "post",
    url: `jobrequests/packing-slip`,
    data: {
      _id: id,
      secretCode: secretCode,
      populate: "business"
    },
  });
}

function getMessagesByParcel(parcelId) {
  return config.axiosDriverInstance({
    method: "get",
    url: "messages/parcel/" + parcelId,
  });
}

function editDriverZones(values) {
  return config.axiosDriverInstance({
    method: "post",
    url: `drivers/custom-zones`,
    data: { zones: values },
  });
}

function fetchDriverZones() {
  return config.axiosDriverInstance({
    method: "get",
    url: `drivers/custom-zones`,
  });
}

const ParcelServices = {
  getParcels, updateParcel, updateParcelStatus, getOneParcel, sendSmsToClient, callClient, smsClient, getSMS,
  sendPosition, deliverPaidParcel, getSortedDrivers, scanLargeParcel, getPackingSlipJob, getMessagesByParcel, editDriverZones, fetchDriverZones
};
export default ParcelServices;
