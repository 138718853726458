import {
  GET_ANNULER_PARCELS_FAILURE,
  GET_ANNULER_PARCELS_REQUEST,
  GET_ANNULER_PARCELS_SUCCESS,
  GET_EN_COURS_PARCELS_FAILURE,
  GET_EN_COURS_PARCELS_REQUEST,
  GET_EN_COURS_PARCELS_SUCCESS,
  GET_LIVRER_PARCELS_FAILURE,
  GET_LIVRER_PARCELS_REQUEST,
  SET_SCANNED_PARCEL,
  GET_LIVRER_PARCELS_SUCCESS,
  GET_RETOUR_PARCELS_FAILURE,
  GET_RETOUR_PARCELS_REQUEST,
  SET_PARCEL_IMAGE,
  PARCEL_SIZE_UPDATED_SUCCESS,
  TOGGLE_LOADING,
  GET_RETOUR_PARCELS_SUCCESS,
  GET_DRIVER_RANKING,
  GET_TOTAL_PARCELS_SUCCESS,
  TOGGLE_SCAN_CAMERA,
  UPDATE_PARCELS_ORDER,
} from "../types";

const initialState = {
  coursParcels: [],
  livreParcels: [],
  annulerParcels: [],
  retourParcels: [],
  ranking: [],
  loading: true,
  total: 1,
  cacheResponse: false,
  scanParcel: true,
  scannedParcels: [],
};

export default function parcels(state = initialState, action) {
  const { type, payload, cacheResponse = false, } = action;
  let parcel;
  let scannedParcels;

  switch (type) {
    case GET_EN_COURS_PARCELS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_EN_COURS_PARCELS_SUCCESS:
      function getDifference(array1, array2) {
        return array1.filter(object1 => {
          return !array2.some(object2 => {
            return object1._id === object2._id;
          });
        });
      }

      let finaldata = [];
      let parcels_from_local = JSON.parse(localStorage.getItem("driverPWA_dragged_items"));
      if (parcels_from_local) {
        for (let i = 0; i < parcels_from_local.length; i++) {
          const e = parcels_from_local[i];
          let item = payload.find((parcel) => parcel._id === e._id);

          if (item) {
            finaldata.push(item);
          }
        }
        let newItems = getDifference(payload, parcels_from_local);
        finaldata = finaldata.concat(newItems);

      } else {
        finaldata = payload;
      }

      return {
        ...state,
        coursParcels: finaldata,
        loading: false,
        cacheResponse,
      };
    case GET_EN_COURS_PARCELS_FAILURE:
      return {
        ...state,
        coursParcels: null,
        loading: false,
      };

    case GET_LIVRER_PARCELS_REQUEST:
      return {
        ...state,
        livreParcels: null,
        loading: true,
      };

    case SET_SCANNED_PARCEL:
      const exists = state.scannedParcels.find((parcel) => parcel._id === payload._id);

      if (!exists) {
        return {
          ...state,
          loading: false,
          scanParcel: false,
          scannedParcels: [...state.scannedParcels, payload],
        };
      }
      return {
        ...state,
        loading: false,
        scanParcel: false,
      };

    case SET_PARCEL_IMAGE:
      parcel = state.scannedParcels.find((parcel) => parcel._id === payload.parcelId);

      parcel.image = payload.image;
      parcel.imageName = payload.imageName;

      scannedParcels = state.scannedParcels.filter((parcel) => parcel._id !== payload.parcelId);

      return {
        ...state,
        loading: false,
        scannedParcels: [...scannedParcels, parcel],
      };

    case PARCEL_SIZE_UPDATED_SUCCESS:
      scannedParcels = state.scannedParcels.filter((parcel) => parcel._id !== payload._id);

      return {
        ...state,
        loading: false,
        scannedParcels,
      };

    case TOGGLE_LOADING:
      return {
        ...state,
        loading: payload,
      };

    case TOGGLE_SCAN_CAMERA:
      return {
        ...state,
        scanParcel: payload,
      };

    case GET_LIVRER_PARCELS_SUCCESS:
      return {
        ...state,
        livreParcels: payload,
        loading: false,
        cacheResponse,
      };
    case GET_LIVRER_PARCELS_FAILURE:
      return {
        ...state,
        livreParcels: null,
        loading: false,
      };

    case GET_ANNULER_PARCELS_REQUEST:
      return {
        ...state,
        annulerParcels: null,
        loading: true,
      };
    case GET_ANNULER_PARCELS_SUCCESS:
      return {
        ...state,
        annulerParcels: payload,
        loading: false,
        cacheResponse,
      };
    case GET_ANNULER_PARCELS_FAILURE:
      return {
        ...state,
        annulerParcels: null,
        loading: false,
      };
    case GET_RETOUR_PARCELS_REQUEST:
      return {
        ...state,
        retourParcels: null,
        loading: true,
      };
    case GET_RETOUR_PARCELS_SUCCESS:
      return {
        ...state,
        retourParcels: payload,
        loading: false,
        cacheResponse,
      };
    case GET_DRIVER_RANKING:
      return {
        ...state,
        ranking: payload.data,
        loading: false,
      };
    case GET_RETOUR_PARCELS_FAILURE:
      return {
        ...state,
        retourParcels: null,
        loading: false,
      };
    case UPDATE_PARCELS_ORDER:
      const items = Array.from(state.coursParcels);
      const [reorderedItem] = items.splice(payload.source.index, 1);
      items.splice(payload.destination.index, 0, reorderedItem);
      localStorage.setItem("driverPWA_dragged_items", JSON.stringify(items));
      return {
        ...state,
        coursParcels: items,
      };
    case GET_TOTAL_PARCELS_SUCCESS:
      return {
        ...state,
        total: payload,
      };
    default:
      return state;
  }
}
